import React from "react";

import styles from "./styles.module.scss";

import { ReactComponent as Eye } from "@/assets/icons/eye.svg";
import { ReactComponent as File } from "@/assets/icons/file-05.svg";
import { ReactComponent as XClose } from "@/assets/icons/x-close.svg";
import { ReactComponent as Check } from "@/assets/icons/check.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { motionButtonProps } from "@/utils/styles/motionSettings";
import { IOrderItem } from "@/store/orders/orders.types";
import { useTranslation } from "react-i18next";
import Status from "@/components/shared/Status/Status";
import dayjs from "dayjs";
import ErrorParser from "@/components/shared/ErrorParser/ErrorParser";
import {
  useDeQueueExecutorMutation,
  useGetExecutorQuery,
} from "@/store/executor/executor.api";
import SearchingIcon from "./SearchingIcon/SearchingIcon";

export default function StatusSwitcher({
  state,
  isTokenEnabled,
  orderError,
  getNextOrder,
  nextOrderError,
  isNextOrderLoading,
  children,
  orderData,
}: {
  orderData?: IOrderItem;
  state: string;
  isTokenEnabled: boolean;
  orderError: any;
  nextOrderError: any;
  getNextOrder: () => void;
  isNextOrderLoading: boolean;
  children?: React.ReactNode;
}) {
  const { t } = useTranslation(undefined, { keyPrefix: "orderPage" });
  const { t: tNext } = useTranslation(undefined, {
    keyPrefix: "orderPage.nextOrder",
  });

  const { data: executorData } = useGetExecutorQuery();
  const [deQueueExecutor] = useDeQueueExecutorMutation();
  if (orderError?.status === 404 || orderData?.is_final_state) {
    if (executorData?.data.payout_state === "in_line") {
      return (
        <>
          <Status
            icon={<SearchingIcon />}
            title={"Поиск выплаты"}
            type="warning"
            description={"Идет поиск новой заявки..."}
          />

          <div className={styles.buttonContainer}>
            <button
              onClick={() => deQueueExecutor()}
              className={styles.cancelButton}
            >
              Отменить поиск
            </button>
          </div>
        </>
      );
    }
  }
  if (orderError?.status === 404) {
    return (
      <>
        <Status
          icon={<XClose />}
          title={t("notFound.title")}
          type="accent"
          description={
            <span
              dangerouslySetInnerHTML={{ __html: t("notFound.description") }}
            />
          }
        />

        <div className={styles.buttonContainer}>
          {nextOrderError && nextOrderError.status !== 404 && (
            <ErrorParser
              error={nextOrderError}
              translates={{
                keys: [
                  {
                    key: "base",
                    translate: t("notFound.nextTranslates.keys.base"),
                  },
                ],
                values: [
                  {
                    key: "token is suspended",
                    translate: t("notFound.nextTranslates.values.suspended"),
                  },
                ],
              }}
            />
          )}
          <motion.button
            {...motionButtonProps}
            className={styles.button}
            onClick={getNextOrder}
            disabled={isNextOrderLoading}
          >
            {"Получить выплату"}
          </motion.button>
        </div>
      </>
    );
  }
  if (orderError?.status) {
    return (
      <>
        <Status
          icon={<XClose />}
          title={`${t("error.title")} ${orderError?.status}`}
          type="danger"
          description={
            <ErrorParser
              error={orderError}
              translates={{
                keys: [
                  {
                    key: "base",
                    translate: tNext("translates.keys.base"),
                  },
                ],
                values: [
                  {
                    key: "token is suspended",
                    translate: tNext("translates.values.suspended"),
                  },
                ],
              }}
            />
          }
        />
      </>
    );
  }
  if (!isTokenEnabled) {
    return (
      <>
        <Status
          icon={<XClose />}
          title={t("tokenDisabled.title")}
          description={t("tokenDisabled.description")}
          type="danger"
        >
          {children}
        </Status>
        <div className={styles.buttonContainer}>
          <motion.button {...motionButtonProps}>
            <Link to={"/profile"} className={styles.button}>
              {t("tokenDisabled.button")}
            </Link>
          </motion.button>
        </div>
      </>
    );
  }
  switch (state) {
    case "pending":
    case "processing":
      return (
        <h4 className={styles.processingTitle}>{t("processing.title")}</h4>
      );

    case "paid":
      return (
        <Status
          icon={<File />}
          type="purple"
          title={t("paid.title")}
          description={`${t("paid.description")} ${dayjs(
            orderData?.expire_at
          ).format("HH:mm DD.MM")}`}
        >
          {children}
        </Status>
      );
    case "verification":
      return (
        <Status
          icon={<Eye />}
          title={t("verification.title")}
          description={t("verification.description")}
          type="warning"
        >
          {children}
        </Status>
      );
    case "completed":
      return (
        <Status
          icon={<Check />}
          title={t("completed.title")}
          description={t("completed.description")}
          type="success"
        >
          {children}
        </Status>
      );
    case "expired":
      return (
        <Status
          icon={<XClose />}
          title={t("expired.title")}
          description={t("expired.description")}
          type="danger"
        >
          {children}
        </Status>
      );
    case "verification_failed":
      return (
        <Status
          icon={<XClose />}
          title={t("verification_failed.title")}
          description={
            <span
              dangerouslySetInnerHTML={{
                __html: t("verification_failed.description"),
              }}
            />
          }
          type="danger"
        >
          {children}
        </Status>
      );
    case "manual_verification":
      return (
        <Status
          icon={<Eye />}
          title={t("manual_verification.title")}
          description={t("manual_verification.description")}
          type="warning"
        >
          {children}
        </Status>
      );
    case "canceled":
      return (
        <Status
          icon={<XClose />}
          title={t("canceled.title")}
          description={t("canceled.description")}
          type="danger"
        >
          {children}
        </Status>
      );
    default:
      return (
        <Status
          icon={<XClose />}
          title={t("default.title")}
          description={t("default.description")}
          type="danger"
        >
          {children}
        </Status>
      );
  }
}
