import React, { useState } from "react";

import styles from "./styles.module.scss";

import { ReactComponent as ChevronDown } from "@/assets/icons/chevron-down-rounded.svg";
import { ReactComponent as ChevronUp } from "@/assets/icons/chevron-up-rounded.svg";
import { Skeleton } from "@mui/material";
import { transformCurrencyValue } from "@/utils/helpers/transformCurrencyValue";
import { useTranslation } from "react-i18next";
import { useGetExecutorQuery } from "@/store/executor/executor.api";

export default function LimitsBlock() {
  const { t } = useTranslation(undefined, {
    keyPrefix: "orderPage.reservesBlock",
  });
  const { data, isLoading } = useGetExecutorQuery();

  if (isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={styles.item}>
            <div className={styles.logo}>
              <Skeleton width={20} height={20} variant="circular" />
            </div>
            <div className={styles.info}>
              <div className={styles.amount}>
                <Skeleton />
              </div>
              <div className={styles.bank}>
                <Skeleton />
              </div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.logo}>
              <Skeleton width={20} height={20} variant="circular" />
            </div>
            <div className={styles.info}>
              <div className={styles.amount}>
                <Skeleton />
              </div>
              <div className={styles.bank}>
                <Skeleton />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.item}>
          <div className={styles.info}>
            <div className={styles.title}>МИН. сумма выплаты</div>
            <div className={styles.content}>
              <ChevronDown />
              {transformCurrencyValue(
                data?.data.min_amount ? data?.data.min_amount : 0
              )}{" "}
              RUB
            </div>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.info}>
            <div className={styles.title}>МАКС. сумма выплаты</div>
            <div className={styles.content}>
              <ChevronUp />
              {transformCurrencyValue(
                data?.data.max_amount ? data?.data.max_amount : 0
              )}{" "}
              RUB
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
