import React, { useEffect } from "react";

import styles from "./styles.module.scss";

import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";
import Reserve from "./Reserve/Reserve";
import TokenBlock from "./TokenBlock/TokenBlock";
import useLogOut from "../../utils/hooks/useLogOut";
import Title from "../../components/typography/Title/Title";
import SbpBlock from "./SbpBlock/SbpBlock";
import C2CBlock from "./C2CBlock/C2CBlock";
import Amount from "../../components/shared/Amount/Amount";
import AccountBlock from "./AccountBlock/AccountBlock";
import CashInBlock from "./CashInBlock/CashInBlock";
import { ReactComponent as LogOutIcon } from "../../assets/icons/log-out-01.svg";
import { motion } from "framer-motion";
import { Skeleton } from "@mui/material";
import { useGetUserInfoQuery } from "../../store/user/user.api";
import { motionButtonProps } from "../../utils/styles/motionSettings";
import { useTranslation } from "react-i18next";
import { useGetExecutorQuery } from "@/store/executor/executor.api";

export default function ProfilePage() {
  const { t } = useTranslation(undefined, { keyPrefix: "profilePage" });
  const { data: userData, isLoading: isUserLoading } = useGetUserInfoQuery();
  const { data: executorData } = useGetExecutorQuery();

  const logOut = useLogOut();

  return (
    <div className={styles.page}>
      <section>
        <div className={styles.amountPayouts}>
          <h3>{t("amount.title")}</h3>
          <p>
            <Amount
              value={userData?.data.payouts_total_limit || "0"}
              postfix="RUB"
            />
          </p>
        </div>
      </section>
      <Title>{t("title")}</Title>
      <section className={styles.section}>
        <div className={classNames(styles.paper, styles.userBlock)}>
          <p className={styles.userName}>
            {isUserLoading ? (
              <Skeleton width={150} height={30} />
            ) : (
              userData?.data.name
            )}
          </p>
          <Tooltip title={t("exit")}>
            <motion.button
              onClick={logOut}
              className={styles.logOut}
              {...motionButtonProps}
            >
              <LogOutIcon />
            </motion.button>
          </Tooltip>
        </div>
      </section>
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>Лимиты</h2>
        {executorData?.data && (
          <Reserve data={executorData?.data} dataField="min_amount" />
        )}
        {executorData?.data && (
          <Reserve data={executorData?.data} dataField="max_amount" />
        )}
      </section>
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>{t("token.title")}</h2>
        <TokenBlock />
        <SbpBlock />
        <C2CBlock />
        <AccountBlock />
        <CashInBlock />
      </section>
    </div>
  );
}
