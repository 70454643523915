import React from "react";

import { motion } from "framer-motion";

export default function SearchingIcon() {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      fill="none"
      animate={{ rotate: 360 }} // Анимация вращения на 360 градусов
      transition={{ repeat: Infinity, duration: 1 }} // Повторяем анимацию бесконечно с длительностью 1 секунду
    >
      <g clipPath="url(#clip0_14493_31338)">
        <path
          d="M12.4993 24C10.3645 23.9995 8.26849 23.4296 6.42745 22.3489C4.5864 21.2683 3.06696 19.7161 2.02583 17.8524C0.984699 15.9887 0.459579 13.8811 0.504626 11.7468C0.549673 9.61249 1.16326 7.52885 2.28209 5.71077C3.40093 3.89268 4.98452 2.40598 6.86952 1.40398C8.75452 0.401986 10.8727 -0.079019 13.0056 0.010575C15.1385 0.100169 17.2088 0.757119 19.0032 1.91367C20.7975 3.07022 22.2508 4.68449 23.2133 6.59C23.2772 6.70766 23.3168 6.83697 23.3299 6.97024C23.3429 7.10351 23.329 7.23804 23.289 7.36585C23.2491 7.49366 23.1838 7.61213 23.0972 7.71426C23.0106 7.81638 22.9044 7.90007 22.7848 7.96035C22.6652 8.02064 22.5348 8.0563 22.4012 8.06522C22.2675 8.07413 22.1335 8.05612 22.007 8.01225C21.8805 7.96838 21.7641 7.89955 21.6646 7.80984C21.5652 7.72013 21.4848 7.61137 21.4283 7.49C20.4147 5.48322 18.7546 3.87651 16.7157 2.92921C14.6768 1.98191 12.3782 1.74929 10.1909 2.2689C8.00353 2.7885 6.05511 4.03002 4.66008 5.79305C3.26505 7.55609 2.50482 9.73778 2.50208 11.986C2.49935 14.2342 3.25427 16.4177 4.645 18.1841C6.03573 19.9506 7.98113 21.1968 10.1672 21.7217C12.3532 22.2467 14.6524 22.0196 16.6936 21.0773C18.7348 20.135 20.3989 18.5323 21.4173 16.528C21.5422 16.3 21.7511 16.1296 21.9996 16.053C22.2481 15.9764 22.5167 15.9996 22.7484 16.1177C22.98 16.2358 23.1566 16.4396 23.2406 16.6857C23.3246 16.9318 23.3094 17.2009 23.1983 17.436C22.1987 19.4144 20.6688 21.076 18.7795 22.2351C16.8902 23.3942 14.7158 24.0053 12.4993 24Z"
          fill="#FEC065"
        />
      </g>
      <defs>
        <clipPath id="clip0_14493_31338">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </motion.svg>
  );
}
