import React, { useEffect, useLayoutEffect } from "react";

import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import "./i18n/config";

import AuthPage from "./pages/AuthPage/AuthPage";
import Layout from "./components/layout/Layout";
import OrderPage from "./pages/OrderPage/OrderPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import OrdersPage from "./pages/OrdersPage/OrdersPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import AutoOrderPage from "./pages/AutoOrderPage/AutoOrderPage";
import Tracker from "@openreplay/tracker";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { useDispatch } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { setFile } from "./store/files/files.slice";
import { ToastContainer } from "react-toastify";
import { useGetUserInfoQuery } from "./store/user/user.api";
import { useGetExecutorQuery } from "./store/executor/executor.api";
import { useTranslation } from "react-i18next";
import { getFingerprint } from "./utils/helpers/getFingerprint";

const tracker = new Tracker({
  projectKey: "qyuIsKsjaeCZLauMLU4V",
  ingestPoint: "https://provider.finboard.cc/ingest",
  capturePerformance: true,
  __DISABLE_SECURE_MODE: process.env.NODE_ENV === "development",
  network: {
    capturePayload: true,
    captureInIframes: true,
    failuresOnly: false,
    ignoreHeaders: false,
    sessionTokenHeader: "",
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#0052ff",
    },
    warning: {
      main: "#fec065",
    },
  },
});

function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { data: userData } = useGetUserInfoQuery();
  const { data: execData } = useGetExecutorQuery();

  useLayoutEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.onmessage = function (event) {
        const file = event.data.file;
        dispatch(setFile(file));
      };
    }
  }, [navigator.serviceWorker]);

  useEffect(() => {
    if (userData?.data) {
      tracker.setUserID(`${userData?.data.team.name}: ${userData?.data.name}`);

      tracker.setMetadata("name", userData?.data.name || "");
      tracker.setMetadata("team_name", userData?.data.team.name || "");
      tracker.setMetadata("team_id", userData?.data.team.id?.toString() || "");

      tracker.setMetadata("executor_id", execData?.data?.id.toString() || "");

      tracker.start();
    }
  }, [userData, execData]);

  async function setFingerprint() {
    const fingerprint = await getFingerprint();
    localStorage.setItem("fingerprint", fingerprint);
  }

  useEffect(() => {
    console.log("test deploy 2");
    setFingerprint();
  }, []);

  return (
    <>
      <BrowserRouter>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={i18n.language ? i18n.language.slice(0, 2) : "ru"}
        >
          <ThemeProvider theme={theme}>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<OrderPage />} />
                <Route path="/auto-order/:id" element={<AutoOrderPage />} />
                <Route path="/upload" element={<></>} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/orders" element={<OrdersPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Route>
              <Route path="/auth" element={<AuthPage />} />
            </Routes>
          </ThemeProvider>
        </LocalizationProvider>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
