const orderPageText = {
  orderPage: {
    timer: {
      description: "Время на оплату заявки:",
      timeIsOver: "Время вышло",
      min: "мин",
      sec: "сек",
    },
    modalEmptyNext: {
      title: "Нет активных заявок",
      description:
        "Система не смогла подобрать заявку под ваши параметры аккаунта.",
      params: "Параметры аккаунта",
      close: "Закрыть",
    },
    modalCancel: {
      title: "Отменить заявку",
      subTitle: "Выберите причину отмены заявки на выплату",
      blocked: {
        title: "Карта получателя заблокирована",
      },
      troubles: {
        title: "Проблемы с оплатой",
        notice:
          "Текущая заявка на выплату перейдет к другому исполнителю, а ваш токен будет отключён",
      },
      submit: "Отменить заявку",
      errors: {
        verification: "Нельзя отменить заявку на проверке",
      },
    },
    modalMakePaid: {
      errors: {
        id: {
          title: "Ошибка",
          description: "Не найден id",
        },
      },
      title: "Не получили чек об оплате?",
      description:
        "Нажмите кнопку <b>Загрузить позднее</b> и тогда MassMo будет считать ваш платеж временно выполненным.",
      warning:
        "В случае подтверждения, вам необходимо приложить чек в течение 12 часов {{date}}, иначе мы отменим выплату",
      submit: "Загрузить позднее",
    },
    reservesBlock: {
      collapse: "Свернуть",
      expand: "Все резервы",
    },
    reservesOver: {
      title: "Резервы исчерпаны",
      description:
        "Вы не можете оплатить заявку, <br /> так как у вас недостаточно резервов",
      button: "Изменить резервы",
    },
    notFound: {
      title: "У вас нет активной заявки на выплату",
      description: 'Нажмите "Получить выплату", <br />для запроса новой заявки',
      nextTranslates: {
        keys: {
          base: "Ошибка",
        },
        values: {
          suspended: "Токен приостановлен",
        },
      },
      button: "Получить заявку на выплату",
    },
    error: {
      title: "Ошибка",
      noBank: "Выберите банк для оплаты",
      invalidFormat: "Этот формат файла не поддерживается",
      maxFileSize: "Максимальный вес файла: 3МБ",
    },
    tokenDisabled: {
      title: "Токен отключен",
      description: "Вы не получаете заявки на выплаты",
      button: "Управление аккаунтом",
    },
    processing: {
      title: "Заявка ожидает оплаты",
    },
    paid: {
      title: "Заявка ожидает загрузки чека",
      description: "Загрузите чек об оплате до",
    },
    verification: {
      title: "Проверяем ваш платеж",
      description: "Система сканирует чек об операции",
    },
    completed: {
      title: "Заявка оплачена",
      description: "Платеж прошел проверку",
    },
    expired: {
      title: "Заявка не была оплачена",
      description: "Истекло время на оплату",
    },
    verification_failed: {
      title: "Платеж не прошел проверку",
      description: "Проверьте корректность чека <br /> и реквизиты получателя",
    },
    manual_verification: {
      title: "Проверка администрацией",
      description: "Платеж на проверке",
    },
    canceled: {
      title: "Заявка отменена",
      description: "Вы отменили данную заявку",
    },
    default: {
      title: "Нераспознан статус заявки",
      description: "Ошибка, не удалось определить статус заявки",
    },
    modalSelectBank: {
      title: "Банк для оплаты",
      description: "Выберите банк для оплаты заявки",
      search: {
        placeholder: "Поиск",
      },
    },
    processingTexts: {
      amount: "Переведите ровно:",
      requisites: "Строго по реквизитам в указанном банке:",
      bankChose: "После оплаты выберите банк, с которого оплатили:",
      receipt: "После оплаты загрузите чек об операции:",
    },
    processingCashInTexts: {
      amount: "Пополните ровно на сумму:",
      requisites: "Строго по реквизитам через Cash-In:",
      receipt: "После оплаты прикрепите ссылки на чеки:",
    },
    receipt: {
      placeholder: "Кликните, чтобы загрузить чек об операции",
    },
    buttonMakePaid: "Оплатили, но не получили чек?",
    submit: "Отправить на проверку",
    nextOrder: {
      translates: {
        keys: {
          base: "Ошибка",
        },
        values: {
          suspended: "Токен приостановлен",
        },
      },
      button: "Перейти к новой заявке",
    },
    detailsBlock: {
      title: "Дополнительная информация",
      createdAt: "Дата создания:",
      uuid: "ID выплаты:",
    },
    cancelBlock: {
      title: "Проблемы с оплатой?",
      cancel: "Отменить выплату",
    },
    amountBlock: {
      title: "Сумма выплаты",
    },
    bankChose: {
      title: "С какого банка вы оплатили заявку?",
      empty: "Не выбран",
      chose: "Выбрать",
    },
    requisiteBlock: {
      empty: "Нет информации о банке",
    },
    verificationFailedBlock: {
      confirm: "Отправить на проверку администрацией",
      modal: {
        title: "Отправить на проверку?",
        description1:
          "Вы уверены, что хотите отправить эту заявку на проверку администрацией?",
        description2:
          "После отправки на проверку, вы не сможете внести изменения в заявку.",
        description3: {
          left: "До окончания ручной проверки выплаты,",
          right: "вы не получите новые заявки для откупа",
        },
      },
    },
    fileInputDrop: {
      placeholder: "Кликните, чтобы загрузить чек об оплате",
      add: "Добавить",
    },
    links: {
      placeholder: "Введите ссылку",
      add: "Добавить ссылку",
      invalidLink: "Некорректная ссылка",
      verification_result: {
        already_used: "Эта ссылка уже используется",
        not_unique: "Ссылка не уникальна",
        invalid_receiver: "Некорректный получатель",
        invalid_amount: "Сумма перевода в чеках меньше чем:",
        invalid_processing_at: "Некорректная дата обработки",
      },
    },
  },
};

export default orderPageText;
