import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface CounterState {
  executorBankName: string;
  isSearching: boolean;
}

const initialState: CounterState = {
  executorBankName: "",
  isSearching: false,
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setExecutorBankName: (state, action: PayloadAction<string>) => {
      state.executorBankName = action.payload;
    },
    setIsSearching: (state, action: PayloadAction<boolean>) => {
      state.isSearching = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setExecutorBankName, setIsSearching } = ordersSlice.actions;
export const selectExecutorBankName = (state: RootState) =>
  state.orders?.executorBankName;
export const selectIsSearching = (state: RootState) =>
  state.orders?.isSearching;

export default ordersSlice.reducer;
