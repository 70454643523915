import React, { useEffect } from "react";

import styles from "./styles.module.scss";

import ReactModal from "react-modal";
import classNames from "classnames";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron-left.svg";

export interface IModalBase {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  style?: React.CSSProperties | undefined;
  contentStyle?: React.CSSProperties | undefined;
  className?: string;
  contentClassName?: string;
  shareDisable?: boolean;
}

export default function ModalBase(props: IModalBase) {
  const { children, style = {}, contentStyle = {} } = props;
  useEffect(() => {
    props.isOpen
      ? document.documentElement.classList.add("hideScroll")
      : document.documentElement.classList.remove("hideScroll");
  }, [props.isOpen]);

  return (
    <ReactModal
      appElement={document && document?.body}
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      overlayClassName={styles.overlay}
      className={classNames(styles.contentModal, props.className)}
      closeTimeoutMS={300}
      style={{
        overlay: {},
        content: {
          ...style,
        },
      }}
    >
      <div
        className={classNames(styles.content, props.contentClassName)}
        style={contentStyle}
      >
        <div className={styles.mobileHead}>
          <button className={styles.back} onClick={props.onClose}>
            <Chevron /> <span>Назад</span>
          </button>
        </div>
        {children}
      </div>
    </ReactModal>
  );
}
