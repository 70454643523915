import React from "react";

import styles from "../styles.module.scss";

import Copy from "../../../../components/shared/Copy/Copy";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { transformCurrencyValue } from "@/utils/helpers/transformCurrencyValue";

export default function DetailsBlock({
  data,
}: {
  data: { created_at: string; uuid: string; rate: number | null };
}) {
  const { t } = useTranslation(undefined, {
    keyPrefix: "orderPage.detailsBlock",
  });
  return (
    <section className={styles.details}>
      <h2>{t("title")}</h2>
      <div className={styles.copyBlock}>
        <div className={styles.left}>{t("createdAt")}</div>
        <div className={styles.right}>
          <time>
            {dayjs(data.created_at.toString().replace(/-/g, "/")).format(
              "DD.MM.YY, в HH:mm"
            )}
          </time>
          <Copy
            value={dayjs(data.created_at.toString().replace(/-/g, "/")).format(
              "DD.MM.YY HH:mm"
            )}
          />
        </div>
      </div>
      <div className={styles.copyBlock}>
        <div className={styles.left}>{t("uuid")}</div>
        <div className={styles.right}>
          <span>
            #{data.uuid?.slice(0, 8)}...{data.uuid?.slice(-5)}
          </span>
          <Copy value={data.uuid} />
        </div>
      </div>
      <div className={styles.copyBlock}>
        <div className={styles.left}>Курс выплаты (USDT/RUB):</div>
        <div className={styles.right}>
          <span>{transformCurrencyValue(data.rate || 0)} RUB</span>
        </div>
      </div>
    </section>
  );
}
