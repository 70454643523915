import { DirectUpload } from "@rails/activestorage";
import type { Blob } from "@rails/activestorage";
import { BASE_URL } from "@/store/base-query.const";
import { useState } from "react";

export function useDirectUpload() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSingle, setIsLoadingSingle] = useState(false);

  async function uploadFileS3(file: File) {
    try {
      setIsLoadingSingle(true);
      const uploadUrl = `${BASE_URL}/rails/active_storage/direct_uploads`;
      const upload = new DirectUpload(file, uploadUrl);

      return await new Promise<Blob>((resolve, reject) => {
        upload.create((error, blob) => {
          if (error) {
            reject(error);
          } else {
            resolve(blob);
          }
        });
      });
    } finally {
      setIsLoadingSingle(false);
    }
  }

  async function uploadFilesS3(files: File[]) {
    try {
      setIsLoading(true);
      return await Promise.all(files.map((file) => uploadFileS3(file)));
    } finally {
      setIsLoading(false);
    }
  }

  return { uploadFileS3, uploadFilesS3, isLoadingSingle, isLoading };
}
