import i18next from "i18next";
import { initReactI18next } from "react-i18next";
// import detector from "i18next-browser-languagedetector";

import ru from "./ru";
import en from "./en";

export const resources = {
  ru: {
    translation: ru,
  },
  en: {
    translation: en,
  },
};

i18next
  // .use(detector)
  .use(initReactI18next)
  .init({
    // if you're using a language detector, do not define the lng option
    debug: true,
    resources,
    // fallbackLng: "en", // use en if detected lng is not available
    fallbackLng: "ru",
    lng: "ru",
    detection: {
      // keys or params to lookup language from
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
    },
  });
