import React, { useState } from "react";

import styles from "./styles.module.scss";

import CurrencyInput from "react-currency-input-field";
import classNames from "classnames";
import Text from "../../../components/typography/Text/Text";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-05.svg";
import { motion } from "framer-motion";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { transformCurrencyValue } from "../../../utils/helpers/transformCurrencyValue";
import { useController, useForm } from "react-hook-form";
import { motionButtonProps } from "../../../utils/styles/motionSettings";
import { useTranslation } from "react-i18next";
import { IExecutor } from "@/store/executor/executor.types";
import { useChangeExecutorMutation } from "@/store/executor/executor.api";

const minAmount = 0;

interface IReserve {
  data: IExecutor;
  dataField: "min_amount" | "max_amount";
}

export default function Reserve({ data, dataField }: IReserve) {
  const { t } = useTranslation(undefined, {
    keyPrefix: "profilePage.reserves",
  });
  const [isEditMode, setEditMode] = useState(false);
  const [isSaved, setSaved] = useState(false);
  const [isError, setError] = useState(false);

  const [updatePayoutLimit, { isLoading, error }] = useChangeExecutorMutation();
  const updateError: any = error;

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });
  const { field } = useController({
    name: "amount",
    control: control,
    rules: {
      required: t("rules.required"),
      min: {
        value: minAmount,
        message: t("rules.min", { count: minAmount }),
      },
    },
  });
  const { onChange, ...amountField } = field;

  async function onSubmit() {
    setError(false);
    let body: any = {
      [dataField]: Number(watch("amount")),
    };
    if (dataField === "min_amount") {
      body.max_amount = data.max_amount;
    } else {
      body.min_amount = data.min_amount;
    }
    const resp: any = await updatePayoutLimit(body);
    if (resp?.data?.status === "ok") {
      setSaved(true);
      setEditMode(false);
    } else {
      setError(true);
    }
  }

  function getEditText() {
    if (updateError?.data?.errors?.base?.length > 0) {
      return (
        <Text type="danger">
          <>{updateError?.data?.errors?.base || " "}</>
        </Text>
      );
    }
    if (isError) {
      return (
        <Text type="danger">
          <>{t("editText.error")}</>
        </Text>
      );
    }
    if (errors?.amount) {
      return (
        <Text type="danger">
          <>{errors?.amount?.message || " "}</>
        </Text>
      );
    }
    if (isSaved) {
      return <Text type="accent">{t("editText.saved")}</Text>;
    }
    return <Text type="muted">{dataField === "min_amount" ? "От" : "До"}</Text>;
  }

  const reserveLeft = (
    <>
      <div
        className={classNames(styles.reserveLeft, {
          [styles.error]: Boolean(errors?.amount),
        })}
      >
        <h4 className={styles.reserveTitle}>{getEditText()}</h4>
        {isEditMode ? (
          <div className={styles.reserveBalance}>
            <span className={styles.reserveCurrency}>RUB</span>
            <CurrencyInput
              className={styles.reserveAmount}
              defaultValue={data?.[dataField] || 0}
              autoFocus
              onValueChange={(value) => onChange({ target: { value } })}
              {...amountField}
            />
          </div>
        ) : (
          <div className={styles.reserveBalance}>
            <span className={styles.reserveCurrency}>RUB</span>
            <div className={styles.reserveAmount}>
              {transformCurrencyValue(data?.[dataField] || 0)}
            </div>
          </div>
        )}
      </div>
      {isEditMode ? (
        <motion.button
          {...motionButtonProps}
          className={classNames(styles.reserveRight, styles.accent)}
          type="submit"
          disabled={!isValid || isLoading}
        >
          <CheckIcon />
        </motion.button>
      ) : (
        <motion.button
          {...motionButtonProps}
          className={styles.reserveRight}
          onClick={() => {
            setEditMode(true);
            setSaved(false);
          }}
          type="button"
        >
          <EditIcon />
        </motion.button>
      )}
    </>
  );
  return (
    <div className={styles.paper}>
      <div>
        {dataField === "max_amount"
          ? "Максимальная сумма выплаты"
          : "Минимальная сумма выплаты"}
      </div>
      {isEditMode ? (
        <form className={styles.reserve} onSubmit={handleSubmit(onSubmit)}>
          {reserveLeft}
        </form>
      ) : (
        <div className={styles.reserve}>{reserveLeft}</div>
      )}
    </div>
  );
}
