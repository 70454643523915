import React, { useEffect } from "react";

import styles from "../styles.module.scss";

import { ReactComponent as CardIcon } from "@/assets/icons/credit-card-upload.svg";
import { motion } from "framer-motion";
import { motionButtonProps } from "@/utils/styles/motionSettings";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import classNames from "classnames";
import ErrorParser from "@/components/shared/ErrorParser/ErrorParser";
import Block from "@/components/shared/Block/Block";
import { useEnQueueExecutorMutation } from "@/store/executor/executor.api";
import { useDispatch } from "react-redux";
import { openModalEmptyNext } from "@/store/ui/ui.slice";
import { setIsSearching } from "@/store/orders/orders.slice";

export default function NextOrderButton({
  refetchActive,
}: {
  refetchActive: () => void;
}) {
  const { t } = useTranslation(undefined, {
    keyPrefix: "orderPage.nextOrder",
  });
  const dispatch = useDispatch();
  const [enQueue, { isLoading, error }] = useEnQueueExecutorMutation();

  async function getNextOrder() {
    try {
      await enQueue().unwrap();
      dispatch(setIsSearching(true));
      refetchActive();
    } catch (e: any) {
      e?.status === 404 && dispatch(openModalEmptyNext());
    }
  }

  return (
    <Block>
      {error && (error as any)?.status !== 404 && (
        <ErrorParser
          error={error}
          translates={{
            keys: [
              {
                key: "base",
                translate: t("translates.keys.base"),
              },
            ],
            values: [
              {
                key: "token is suspended",
                translate: t("translates.values.suspended"),
              },
            ],
          }}
        />
      )}

      <motion.button
        {...motionButtonProps}
        className={classNames(styles.paper, styles.newOrder)}
        onClick={getNextOrder}
      >
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <CardIcon /> {t("button")}
          </>
        )}
      </motion.button>
    </Block>
  );
}
